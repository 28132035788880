
import React from 'react';
import styled from 'styled-components';
import { Box, Link } from '@primitives';
import { observer } from 'mobx-react';
import { Form } from '@components/Form';
import { graphql } from 'gatsby';
import { Layout } from '@components/Layout';
import { LOGIN } from '@constants';
import { useStores } from '@stores';

type NavItem = {
  href: string;
  title: string;
};

interface ILoginPage {
  data: IMarkdownData;
}

const WhiteBox = styled(Box)`
  width: calc(100% - 4rem);
`;

const LinkContainer = styled(Box)`
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 400px;
  a:first-of-type {
    border-right: 2px solid ${(props) => props.theme.colors.black};
    margin-right: 1rem;
    padding-right: 1rem;
    display: flex;
  }
`;

const StyledLink = styled(Link)`
  margin-bottom: 1rem;
`;
const LoginPage: React.FC<ILoginPage> = observer(({
  data: {
    markdownRemark: { frontmatter: { links }, html },
  },
}) => {
  const { authStore: { login } } = useStores();
  const onLoginSubmit = async (data: { email: string; password: string }, callback: Function) => {
    return login(data, callback);
  };

  return (
    <Layout twoColumnLayout>
      <Box pl="2" pr="2">
        <Form
          id="login"
          fields={LOGIN}
          values={{}}
          onSubmit={onLoginSubmit}
          submitText="pages.login.login"
          styles={['centerLabels', 'singleColumn']}
        />
        <LinkContainer mt="4">
          {links.map((link: NavItem) => (
            <StyledLink key={link.href} to={link.href} underline>
              {link.title}
            </StyledLink>
          ))}
        </LinkContainer>
      </Box>
      <WhiteBox p="2" mt="2" color="white">
        <div dangerouslySetInnerHTML={{ __html: html! }} />
      </WhiteBox>
    </Layout>
  );
});

export const pageQuery = graphql`
query LoginFormQuery {
  markdownRemark(frontmatter: {path: {eq: "/login/"}}) {
    frontmatter {
      title
      path
      links {
        href
        title
      }
    }
    html
  }
}
`;

export default LoginPage;
